import { title } from '@core/utils/filter';

export default [
  {
    key: 'nickname',
    label: 'Nickname',
    formatter: title,
    sortable: true,
    visible: true,
  },
  {
    key: 'source',
    label: 'Source',
    sortable: false,
    visible: true,
  },
  {
    key: 'status',
    label: 'Status',
    thClass: 'text-center',
    tdClass: 'text-center pt-1',
    visible: true,
  },
  {
    key: 'program',
    label: 'Fanpage',
    visible: true,

  },
  {
    key: 'recomendations',
    label: 'Recommendations',
    thClass: 'text-center',
    tdClass: 'text-center pt-1',
    visible: true,
  },
  {
    key: 'created_at',
    label: 'Created date',
    thClass: 'text-center',
    tdClass: 'text-center pt-1',
    visible: true,
  },
  {
    key: 'catcher',
    label: 'Owner',
    thClass: 'text-center',
    tdClass: 'text-center pt-1',
    visible: true,
  },
  {
    key: 'client_ceo',
    label: 'client of ceo',
    thClass: 'text-center',
    tdClass: 'text-center pt-1',
    visible: true,
  },
  {
    key: 'potential',
    label: 'Potential',
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: 'actions',
    label: 'Actions',
    thClass: 'text-center',
    tdClass: 'text-center pt-1',
    visible: true,
  },

];
