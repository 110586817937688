<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <filter-slot
        v-scrollbar
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalLeads"
        :paginate="paginate"
        :start-page="fromPage"
        :to-page="toPage"
        :send-multiple-sms="false"
        @reload="getSocialNetworkLeadsPotential"
        @onChangeCurrentPage="onChangeCurrentPage"
      >
        <b-table
          slot="table"
          class="position-relative"
          ref="refTable"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="50vh"
          small
          show-empty
          :sort-by.sync="sortBy"
          :fields="visibleFields"
          :items="S_W_POTENTIAL_LEADS"
          :sort-desc.sync="isSortDirDesc"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(nickname)="data">
            <div style="white-space: pre-wrap">
              <router-link
                :class="textLink"
                :to="`/socialnetwork/leads/new/dashboard/${data.item.lead_id}`"
                target="_blank"
                >{{
                  data.item.nickname
                    ? data.item.nickname
                    : data.item.not_nickname
                }}</router-link
              >
              <br />
              <small>{{ data.item.lead_name }}</small>
            </div>
            <div v-if="data.item.user_responsible_date" class="text-warning font-weight-bolder">
              <feather-icon
                style="margin-right: 3px;"
                icon="StarIcon"
              />
              CEO's client
            </div>
          </template>

          <template #cell(source)="data">
            <div>
              <b-img
                fluid
                :src="baseUrl + '/images/social-network/facebook.png'"
                style="width: 30px; margin-top: 7px"
                v-if="
                  data.item.sourcesname_id == 17 &&
                  data.item.lead_source_id == null
                "
              />
              <b-img
                fluid
                :src="baseUrl + '/images/social-network/google.png'"
                style="width: 30px; margin-top: 7px"
                v-if="
                  data.item.sourcesname_id == 32 &&
                  data.item.lead_source_id == null
                "
              />
              <b-img
                fluid
                :src="data.item.source_icon_url"
                style="width: 30px; margin-top: 7px"
                v-if="
                  data.item.source_icon_url !== null &&
                  data.item.lead_source_id !== null
                "
              />
            </div>
          </template>

          <template #cell(status)="data">
            <div>
              <b-badge
                :variant="`light-${resolveLeadSnStatusVariant(
                  data.item.status_sn_id
                )}`"
                class="text-capitalize w-100"
              >
                {{ data.item.status }}
              </b-badge>
            </div>
          </template>

          <template #cell(program)="data">
            <div>
              <b-img
                thumbnail
                fluid
                :src="baseUrl + data.item.logo"
                style="width: 50px"
                v-if="data.item.logo"
              />
              <b-badge variant="primary" v-else style="width: 40px">
                CRM
              </b-badge>
            </div>
          </template>

          <template #cell(recomendations)="data">
            <div>
              <small
                v-for="(program, index) in JSON.parse(data.item.programs)"
                :key="index"
              >
                {{ program }} <br />
              </small>
            </div>
          </template>

          <!-- Column: Created Date -->
          <template #cell(created_at)="data">
            <small>{{ data.item.created_at | myGlobalDay }}</small>
          </template>

          <template #cell(potential)="data">
            <potential-lead-switch
              :lead-id="data.item.lead_id"
              :potential-status="data.item.potential_status"
              :lead-name="
                data.item.not_nickname ? data.item.not_nickname : data.item.nickname
              "
              @update="getSocialNetworkLeadsPotential"
            />
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-center p-0">
              <div>
                <b-dropdown variant="link" no-caret>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="motivePotencial(data.item, 1)">
                    <feather-icon
                      icon="UserCheckIcon"
                      size="20"
                      class="mr-50 text-primary mx-2 cursor-pointer"
                    />
                    <span class="align-middle ml-50"> POTENTIAL</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="motivePotencial(data.item, 2)">
                    <feather-icon
                      icon="UserXIcon"
                      size="20"
                      class="mr-50 text-danger mx-2 cursor-pointer"
                    />
                    <span class="align-middle ml-50"> NOT POTENTIAL </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.can_delete"
                    @click="deleteLead(data.item.lead_id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="20"
                      class="mr-50 text-danger mx-2 cursor-pointer"
                    />
                    <span class="align-middle ml-50"> DELETE </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>

          <template #cell(client_ceo)="data">
            <span class="text-warning">
              <template
                v-if="data.item.user_responsible_date"
                class="mr-50"
              >From</template> {{ data.item.user_responsible_date | myGlobal }}
            </span>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
    <ModalMotivePotencial
      v-if="lead.status"
      :lead="lead"
      @hideModal="hideModal"
      :type-reason="typeReason"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import dataFilters from "./filters.data";
import Fields from "./fields.data";
import helpers from "@/views/social-network/helpers";
import ModalMotivePotencial from "@/views/social-network/views/leads/tabs/without-leads/modal/ModalMotivePotencial.vue";
import WithoutLeadsService from "@/views/social-network/views/leads/tabs/without-leads/services/without-leads.service";
import PotentialLeadSwitch from "@/views/social-network/views/leads/components/commons/PotentialLeadSwitch.vue";

export default {
  components: {
    ModalMotivePotencial,
    PotentialLeadSwitch,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL_ASSETS,
      filters: dataFilters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      totalLeads: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      fromPage: 0,
      toPage: 0,
      sortBy: "id",
      fields: Fields,
      isSortDirDesc: false,
      isBusy: false,
      typeReason: 6,
      lead: {
        status: false,
        type: null,
        lead_name: null,
        nickname: null,
        lead_id: null,
      },
    };
  },
  computed: {
    ...mapState({
      S_W_POTENTIAL_LEADS: (state) => state.CrmLeadStore.S_W_POTENTIAL_LEADS,
    }),
    ...mapState("SocialNetworkLeadsStore", ["S_SELLERS_FILTERS"]),
    ...mapState("SocialNetworkGlobalStore", ["S_STATES"]),
    ...mapState("auth", ["currentUser"]),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      return this.fields.filter(f => f.visible);
    },
  },
  created() {
    this.setOptionsOnFilters();
    /* Set fields visiblity */
    const isPermittedToModifiyLeadStatusPotential = this.isSupervisor || this.isCeo || this.isOwner;
    this.fields = this.setFieldVisibility(this.fields, 'potential', isPermittedToModifiyLeadStatusPotential);
  },
  methods: {
    ...helpers,
    ...mapActions("SocialNetworkLeadsStore", [
      "A_SET_FILTERS",
      "A_GET_FILTER_SELLERS",
      "A_DELETE_LEAD",
    ]),
    ...mapActions("SocialNetworkGlobalStore", ["A_GET_STATES"]),
    ...mapActions("CrmLeadStore", [
      "A_GET_W_POTENTIAL_LEADS",
      "A_UPDATE_STATUS_POTENTIAL_SN",
    ]),
    ...mapMutations("CrmLeadStore", ["M_SET_ACTIONS_STATUS_POTENTIAL"]),
    async getSocialNetworkLeadsPotential() {
      try {
        this.isBusy = true;
        this.setFilters();
        const response = await this.A_GET_W_POTENTIAL_LEADS({
          name_text: this.filterPrincipal.model,
          lead_status: null,
          cr: null,
          program: null,
          date_from: this.filters[0].model,
          date_to: this.filters[1].model,
          orderby: 10,
          order: "desc",
          user_owner: this.filters[2].model,
          type: 3,
          lead_situation: 2,
          state_h: this.filters[4].model,
          sourcename: this.filters[3].model,
          reason_not: null,
          subsource: null,
          potential: 2,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
        });
        this.totalLeads = response.total;
        this.fromPage = response.from;
        this.toPage = response.to;
        this.isBusy = false;
      } catch (error) {
        console.log(
          "Somtehing went wrong getSocialNetworkLeadsPotential",
          error
        );
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
      this.getSocialNetworkLeadsPotential();
    },
    setFilters() {
      this.A_SET_FILTERS({
        from: this.filters[0].model,
        to: this.filters[1].model,
      });
    },
    async confirmPotential(lead_id, status_potential) {
      const result = await this.showConfirmSwal(
        "Are you sure?",
        "You won't be able to revert this!",
        "question"
      );
      if (result.value) {
        const response = await this.A_UPDATE_STATUS_POTENTIAL_SN({
          lead_id: lead_id,
          status_potential: status_potential,
        });
        if (this.isResponseSuccess(response)) {
          if (status_potential == 3) {
            await this.M_SET_ACTIONS_STATUS_POTENTIAL({
              lead_id: lead_id,
              status_potential: status_potential,
            });
          }
          this.showToast();
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            `Something went wrong.${response.message}`
          );
        }
      }
    },

    async setOptionsOnFilters() {
      await Promise.all([
        this.A_GET_FILTER_SELLERS({ moduleId: this.moduleId, roles: "[]" }),
        this.A_GET_STATES({ type: 1 }),
      ]);
      this.filters[2].options = this.S_SELLERS_FILTERS;
      this.filters[4].options = this.S_STATES;
    },
    resolveLeadSnStatusVariant(status) {
      if (status === 2) return "success";
      if ([3, 4].includes(status)) return "primary";
      if (status === 5) return "secondary";
      if (status === 6) return "warning";
      if (status === 7) return "danger";
      return "primary";
    },
    async motivePotencial(item, type) {
      this.typeReason = type == 1 ? 8 : 6;
      this.lead.type = type;
      this.lead.lead_name = item.lead_name;
      this.lead.nickname = item.nickname;
      this.lead.lead_id = item.lead_id;

      this.lead.status = true;
    },
    async getMotivePotentialList() {
      try {
        // Type 6 NO POTENTIAL
        const { data, status } =
          await WithoutLeadsService.getMotivePotentialList({
            type: 6,
            isPotential: true,
          });
        if (status == 200) {
          let id = data[0].id;
          return id;
        }
      } catch (e) {
        console.log(e);
      }
    },
    hideModal(type) {
      this.lead.status = false;
      if (type == 2 || type == 1) {
        this.getSocialNetworkLeadsPotential();
      }
    },
    async deleteLead(id) {
      const result = await this.showConfirmSwal(
        "Are you sure?",
        "You won't be able to revert this!",
        "question"
      );
      if (result.value) {
        const { user_id } = this.currentUser;
        const response = await this.A_DELETE_LEAD({
          lead_id: id,
          user_id: user_id,
        });
        this.getSocialNetworkLeadsPotential();

        if (this.isResponseSuccess(response)) {
          this.showToast(
            "success",
            "top-right",
            "Deleted!",
            "CheckIcon",
            "Your file has been deleted."
          );
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            `Something went wrong.${response.message}`
          );
        }
      }
    },
  },
  mounted() {
    if ([1, 2].includes(this.currentUser.role_id) && this.type === 0)
      this.actionsOptions.push("delete");
  },
};
</script>