import { amgApi } from "@/service/axios";

class WithoutLeadsService {
  async updateStatusPotencial(params) {
    try {
      const data = await amgApi.post("/lead/social-network/update-status-potencial", params);
      return data;
    } catch (error) {
      console.log("Error updateStatusPotencial");
    }
  }
  async getMotivePotentialList(params) {
    try {
      const data = await amgApi.post("/lead/social-network/get-motive-potentia-list", params);
      return data;
    } catch (error) {
      console.log("Error getMotivePotentialList");
    }
  }
}

export default new WithoutLeadsService();
