<template>
  <div>
    <b-modal
      ref="motive-potenial-modal"
      title="NOT POTENTIAL"
      size="sm"
      modal-class="modal-primary "
      hide-footer
      @hidden="hideModal"
      no-close-on-backdrop
      title-tag="h3"
    >
      <b-input-group class="my-2">
        <template #prepend>
          <b-input-group-text>{{
            lead.lead_name && lead.lead_name != "  " ? "LEAD NAME" : "NICK NAME"
          }}</b-input-group-text>
        </template>
        <b-form-input
          :value="
            lead.lead_name && lead.lead_name != '  '
              ? lead.lead_name
              : lead.nickname
          "
          readonly
        />
      </b-input-group>

      <validation-observer ref="refIsNotPotencial">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group
            label="Motive"
            label-for="motive"
            :state="errors[0] ? false : null"
          >
            <v-select
              v-model="motive"
              label="description"
              :options="motiveList"
              :reduce="(motive) => motive.id"
            />
          </b-form-group>
        </validation-provider>
      </validation-observer>
      <b-row>
        <!-- save button -->
        <b-col cols="12" md="12" class="mt-1 text-center">
          <b-button
            type="button"
            variant="primary"
            class="mr-1"
            @click="onSubmit"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import WithoutLeadsService from "@/views/social-network/views/leads/tabs/without-leads/services/without-leads.service"
import { mapGetters } from "vuex"
export default {
  props: {
    lead: {
      type: Object,
    },
    typeReason:{
      type: Number,
      required: false,
      default: 6
    }
  },
  data() {
    return {
      motive: "",
      motiveList: [],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.getMotivePotentialList()
  },
  mounted() {
    this.toggleModal("motive-potenial-modal")
  },
  methods: {
    hideModal() {
      this.$emit("hideModal", 1)
    },
    async getMotivePotentialList() {
      try {
        // Type 6 NO POTENTIAL
        const { data, status } =
          await WithoutLeadsService.getMotivePotentialList({
            type: this.typeReason,
            isPotential: false,
          })
        if (status == 200) {
          this.motiveList = data || []
        }
      } catch (e) {
        console.log(e)
      }
    },
    async onSubmit() {
      try {
        const validate = await this.$refs.refIsNotPotencial.validate()
        if (validate) {
          const confirm = await this.showConfirmSwal()
          if (confirm.isConfirmed) {
            this.addPreloader()
            let params = {
              type: this.lead.type,
              lead_id: this.lead.lead_id || this.lead.id,
              motive: this.motive,
              user_id: this.currentUser.user_id,
              status: this.lead.type == 1 ? null : 0,
            }
            const { data } = await WithoutLeadsService.updateStatusPotencial(
              params
            )
            this.$emit("hideModal", this.lead.type, data)
            this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY")
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.removePreloader()
      }
    },
  },
}
</script>
